import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import axios from 'axios'
import validator from 'validator'

import { Modal, Button } from 'antd';
import "../css/rahul-styles.css"

const PopupForm = (props) => {

  const {time, handleTimeChange} = props 


  useEffect(() => {
    setTimeout(() => {
      showModal()
    }, time)
  }, [time])

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [qualification, setQualification] = useState('')
  const [destination, setDestination] = useState('')
  const [abroad, setAbroad] = useState('')
  // const [interested, setInterested] = useState([])
  const [questions, setQuestions] = useState('')
  const [policy, setPolicy] = useState(false) 
  const [formError, setFormError] = useState({})
  const errors = {}

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const options = [
  //   { value: 'withinThreeMonths', label: 'Within 3 Months' },
  //   { value: 'threeToSixMonths', label: '3 - 6 MOnths' },
  //   { value: 'sixToTwelveMonths', label: '6 - 12 Months' },
  //   { value: 'afterTwelveMonths', label: 'After 12 Months' },
  //   { value: 'notPlanningToGoAbroad', label: 'Not Planning To Go Abroad' }
  // ]

  // const helpOptions = [
  //   { value: 'freeTrialClasses', label: 'Free Trial Classes' },
  //   { value: 'englishTestPreparation', label: 'English Test Preparation' },
  //   { value: 'loanApplicationAssistance', label: 'Loan Application Assistance' },
  //   { value: 'visaCounselling', label: 'Visa Counselling' }
  // ]

  const handleChange = (e) => {
    if(e.target.name === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'number') {
      setNumber(e.target.value)
    } else if (e.target.name === 'qualification') {
      setQualification(e.target.value)
    } else if (e.target.name === 'destination') {
      setDestination(e.target.value)
    } else if (e.target.name === 'questions') {
      setQuestions(e.target.value)
    } else if (e.target.name === 'abroad') {
      setAbroad(e.target.value)
    }
  }

  const handlePolicy = (e) => {
    const policyInput = e.target.checked 
    setPolicy(policyInput) 
  }

  const runValidations = () => {
    if(firstName.trim().length === 0) {
      errors.firstName = "Required"
    } 
    // if (lastName.trim().length === 0) {
    //   errors.lastName = "Required"
    // }
    if (email.trim().length === 0) {
      errors.email = "Required"
    } else if (!validator.isEmail(email)) {
      errors.email = "Invalid E-Mail Format"
    }
    if (number.trim().length === 0) {
      errors.number = "Required"
    } else if (number.trim().length < 10) {
      errors.number = "Atleast 10 digits required"
    } 
    if (!qualification) {
      errors.qualification = "Required"
    }
    if (!destination) {
      errors.destination = "Required"
    }
    // if (!abroad) {
    //   errors.abroad = "Required"
    // }
    // if( selected.length === 0 ) {
    //   errors.selected = "Select atleast one"
    // }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    runValidations()

    // const multiSelectOptions = selected.map(item => {
    //   return (
    //     item.label
    //   )
    // })

    // const helpSelectOptions = interested.map(item => {
    //   return (
    //     item.label 
    //   )
    // })

    // console.log(helpSelectOptions)
    // console.log(multiSelectOptions)

    if(Object.keys(errors).length === 0) {
      setFormError({})
      const formData = {
        firstName: firstName,
        // lastName: lastName,
        email: email,
        number: number,
        destination: destination,
        // interested: interested,
        qualification: qualification,
        // questions: questions,
        policy: policy,
        // abroad: abroad
        // areaStudy: multiSelectOptions
      }


      setFirstName('')
      // setLastName('')
      setEmail('')
      setNumber('')
      setDestination('')
      // setInterested([])
      setQualification('')
      // setQuestions('')
      // setAbroad('')
      // setSelected([])
      setPolicy(false)

      // const arrSelected = selected.map(item => {
      //   return item.label
      // })

      swal("Done!", "Our Admissions team will get in touch with you soon.", "success");

      handleCancel()

      axios.post('https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r818cc16ada0b011c2981128aabe70284&secretKey=65a4a7aea451fbd17be5958bff3d23abe3bcb92f', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
        // {
        //   "Attribute": "LastName",
        //   "Value": lastName
        // },
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": qualification
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": destination 
        },
        // {
        //   "Attribute": "mx_Target_Discipline",
        //   "Value": JSON.stringify(arrSelected)
        // },
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
        // {
        //   "Attribute": "mx_Help_With",
        //   "Value": JSON.stringify(helpSelectOptions)
        // },
        // {
        //   "Attribute" : "mx_Timeline_for_Going_Abroad",
        //   "Value" : abroad
        // },
        // {
        //   "Attribute": "mx_Questions_from_Student",
        //   "Value": questions
        // },
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy
        },
        {
          "Attribute": "Source",
          "Value": "Website Enquiry"
        }
      ])
      .then(response => {
      })
      .catch(err => {
        alert(err.message)
        //console.log(err.message)
      })

    } else {
      setFormError(errors)
    }
  }


  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal className="antd-modal-main" footer="null" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000} >
        <div className="container-fluid p-0">
          <div className="row m-0">
            <div className="col-md-4 left-col p-0">
            </div>

            <div className="col-md-8 p-0">
              <div className="row top-row-img ">
                <div className="col-12 w-100 p-0">
                  <div className="header-text">
                    <h1 className="main-heading-text"> Get in touch with us </h1>
                  </div>
                </div>
              </div>

              <div className="container form-container">
                <div className="row">
                  <div className="col-12 mb-1">
                    <p className="sub-heading mb-0 text-color">
                      PERSONAL
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label form-label-popup">NAME*</label> {formError.firstName &&  <span className="text-danger error-message-text"> {formError.firstName} </span> }
                    <input 
                      name='firstName'
                      value={firstName}
                      onChange={handleChange}
                      type="text" 
                      className="form-control form-control-popup text-color" placeholder="Type Here" 
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <label className="form-label  form-label-popup">LAST NAME*</label> {formError.lastName && <span className="text-danger error-message-text"> {formError.lastName} </span>}
                    <input
                      name='lastName'
                      value={lastName}
                      onChange={handleChange}
                      type="text" 
                      className="form-control form-control-popup" 
                      placeholder="Type Here" 
                    />
                  </div> */}
                  <div className="col-md-6">
                    <label className="form-label form-label-popup">E-MAIL*</label> {formError.email && <span className="text-danger error-message-text"> {formError.email} </span>}
                    <input
                      name='email'
                      value={email}
                      onChange={handleChange}
                      type="email" 
                      className="form-control form-control-popup" 
                      placeholder="Type Here" 
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label form-label-popup">PHONE NUMBER*</label> {formError.number && <span className="text-danger error-message-text"> {formError.number} </span>}
                    <input 
                      name='number'
                      value={number}
                      onChange={handleChange}
                      type="number" 
                      className="form-control form-control-popup" 
                      placeholder="+91" 
                    />
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12 mb-1">
                    <p className="sub-heading mb-0 text-color"> EDUCATION </p>
                  </div>
                  <div className="col-md-6">
                    
                    <label className="form-label form-label-popup"> QUALIFICATION YOU'RE SEEKING* </label> 
                    
                    <select 
                      className="form-select form-select-popup"
                      name="qualification"
                      value={qualification}
                      onChange={handleChange}
                    >
                      <option selected> Select One </option>
                      <option value="Undergraduate"> Undergraduate </option>
                      <option value="Postgraduate"> Postgraduate </option>
                    </select>
                    {formError.qualification && <span className="text-danger error-message-text"> {formError.qualification} </span>}
                  </div>
                  <div className="col-md-6">
                    
                    <label className="form-label form-label-popup"> PREFERRED EDUCATION DESTINATION* </label>

                    <select
                      name="destination"
                      value={destination}
                      onChange={handleChange}
                      className="form-select form-select-popup"
                    >
                      <option selected> Select One </option>
                      <option value="Australia"> Australia </option>
                      <option value="Dubai"> Dubai </option>
                      <option value="United Kingdom"> United Kingdom </option>
                      <option value="United States"> United States </option>
                      <option value="Other"> Other </option>
                    </select>
                    {formError.destination && <span className="text-danger error-message-text"> {formError.destination} </span>}
                  </div>
                  {/* <div className="col-12 multi-select-container">
                  <label className="form-label form-label-popup"> WHEN ARE YOU PLANNING TO GO ABROAD? </label> 
                    
                    <select 
                      className="form-select form-select-popup"
                      name="abroad"
                      value={abroad}
                      onChange={handleChange}
                    >
                      <option selected> Select One </option>
                      <option value="Within 3 months"> Within 3 months </option>
                      <option value="In 3 - 6 months"> In 3 - 6 months </option>
                      <option value="In 6 - 12 months"> In 6 - 12 months </option>
                      <option value="After 12 months"> After 12 months </option>
                      <option value="Not planning to go abroad"> Not planning to go abroad </option>
                    </select>
                    {formError.abroad && <span className="text-danger error-message-text"> {formError.abroad} </span>}
                  </div> */}
                  <div className="col-md-6 multi-select-container">
                    {/* <label className="form-label form-label-popup">DO YOU NEED ANY HELP WITH?</label>
                    <MultiSelect
                      disableSearch
                      options={helpOptions}
                      value={interested}
                      onChange={setInterested}
                      labelledBy="Select atleast one"
                    />
                    {formError.interested && <span className="text-danger error-message-text" > {formError.interested} </span>} */}
                  </div>
                  {/* <div className="col-md-6">
                    <label className="form-label form-label-popup">
                      DO YOU NEED ANY HELP WITH?
                    </label>
                    <select
                      name="interested"
                      value={interested}
                      onChange={handleChange}
                      className="form-select form-select-popup"
                      aria-label="Default select example"
                    >
                      <option selected>Select one</option>
                      <option value="Free Trial Classes">Free Trial Classes</option>
                      <option value="English Test Preparation"> English Test Preparation </option>
                      <option value="Loan Application Assistance">Loan Application Assistance</option>
                      <option value="Visa Counselling">Visa Counselling</option>
                    </select>
                  </div> */}
                </div>

                <div className="row">
            
                  {/* <label style={{ marginLeft: "15px" }} className="form-label form-label-popup">DO YOU HAVE ANY OTHER QUESTIONS?</label>
                  <div className="col-12">
                    <input 
                      type="text" 
                      className="form-control form-control-popup" 
                      placeholder="Ask a question"
                      onChange={handleChange}
                      name="questions"
                      value={questions}
                    />
                  </div> */}

                  <div className="col-12" style={{ marginLeft: "20px" }}>
                    
                    <label className="form-check-label" style={{ fontWeight: "500" }} >
                      <input 
                        checked={policy}
                        onChange={handlePolicy}
                        className="form-check-input" 
                        type="checkbox" 
                      />
                      I have read and understood <a href="/privacypolicy"> The WorldGrad Privacy Policy </a>
                    </label>
                  </div>         
                </div>

                <div className="row justify-content-center">
                  <div className="col-md-4 button-body">
                    <a href="#">
                      <button 
                        type="button" 
                        className="btn btn-warning"
                        onClick={handleSubmit}
                        disabled={!policy}
                      >SUBMIT</button>
                    </a>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default PopupForm
